import React, { useState, useEffect, useCallback } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CircularProgress from '@mui/material/CircularProgress';


const LoginDialog = () => {
  const [credentials, setCredentials] = useState({ username: '', password: '' });
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const BACKEND_HOST = process.env.REACT_APP_BACKEND_HOST;

  const checkTokenValidity = useCallback(async () => {
    try {
      const response = await axios.get(`${BACKEND_HOST}/admin/checkToken`, {
        headers: {
          Authorization: `Bearer ${Cookies.get('jwt')}`,
        },
      });

      if (response.data.code === 200) {
        if (response.data.new_access_token) {
          Cookies.set('jwt', response.data.new_access_token, {
            expires: 7,
            secure: process.env.NODE_ENV === 'production',
            sameSite: 'Strict',
          });
        }
        Cookies.set('isLoggedIn', 'true');
        navigate('/adminPanel');
      } else {
        throw new Error('Invalid token');
      }
    } catch (error) {
      console.error('Token validation error:', error);
      Cookies.remove('jwt');
      Cookies.remove('isLoggedIn');
    } finally {
      setLoading(false);
    }
  }, [navigate]);

  useEffect(() => {
    if (Cookies.get('jwt')) {
      checkTokenValidity();
    } else {
      setLoading(false);
    }
  }, [checkTokenValidity]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCredentials(prev => ({ ...prev, [name]: value }));
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(`${BACKEND_HOST}/admin/login`, credentials, {
        headers: { 'Content-Type': 'application/json' },
      });

      console.log(data);
      
      if (data.code === 200) {
        toast.success("Login Success!");
        Cookies.set('jwt', data.access_token, {
          expires: 7,
          secure: process.env.NODE_ENV === 'production',
          sameSite: 'Strict',
        });
        navigate('/adminPanel');
      } else {
        
        
        throw new Error('Invalid credentials');
      }
    } catch (error) {
      console.error('Login error:', error);
      toast.error(error.response?.data?.message || "Invalid username or password!");
    }
  };

  if (loading) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <Dialog open={true}>
      <DialogTitle>Login</DialogTitle>
      <form onSubmit={handleLogin}>
        <DialogContent>
          <DialogContentText>Please enter your credentials:</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            name="username"
            label="User ID"
            type="text"
            fullWidth
            value={credentials.username}
            onChange={handleInputChange}
            required
          />
          <TextField
            margin="dense"
            name="password"
            label="Password"
            type="password"
            fullWidth
            value={credentials.password}
            onChange={handleInputChange}
            required
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit">Login</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default LoginDialog;