import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { FaHome, FaUsers, FaBriefcase, FaBlog, FaEnvelope } from "react-icons/fa"; // Example with Font Awesome
import "./style.scss";

const HeaderBottom = (props) => {
  const [responsive, setResponsive] = useState(false);
  const toggleResponsiveMenu = () => {
    setResponsive(!responsive);
  };

  return (
    <div className={`headerBottom ${props.className}`}>
      <div className="container">
        <div className="headerBottomMainWrapper">
          {/* Main Menu */}
          <div className={`mainMenuWrap ${responsive ? "active" : ""}`}>
            <ul className="menu">
              <li>
                <NavLink exact to="/" activeClassName="active">
                  <FaHome className="menuIcon" /> Home
                </NavLink>
              </li>
              <li>
                <NavLink exact to="/about" activeClassName="active">
                  <FaUsers className="menuIcon" /> About
                </NavLink>
              </li>
              <li>
                <NavLink exact to="/team" activeClassName="active">
                  <FaUsers className="teamIcon" /> Our Team
                </NavLink>
              </li>
              <li>
                <NavLink exact to="/practice" activeClassName="active">
                  <FaBriefcase className="menuIcon" /> Practice
                </NavLink>
              </li>
              <li>
                <NavLink exact to="/blogs" activeClassName="active">
                  <FaBlog className="menuIcon" /> Blogs
                </NavLink>
              </li>
              <li>
                <NavLink exact to="/contact" activeClassName="active">
                  <FaEnvelope className="menuIcon" /> Contact
                </NavLink>
              </li>
            </ul>
          </div>

          {/* Responsive Toggle */}
          <div className="responsiveTrigger" onClick={toggleResponsiveMenu}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderBottom;
