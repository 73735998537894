import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import { AppBar, Tabs, Tab, Typography, Box } from '@mui/material';
import Queries from '../../../components/Queries';
import Blogs from '../../../components/Blogs';
import Admins from '../../../components/Admins';
// import ImagesVideos from '../..'; // Assuming this component exists

const TabPanel = ({ children, value, index, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`full-width-tabpanel-${index}`}
    aria-labelledby={`full-width-tab-${index}`}
    {...other}
  >
    {value === index && (
      <Box sx={{ p: 3 }}>
        <Typography component="div">{children}</Typography>
      </Box>
    )}
  </div>
);

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index) => ({
  id: `full-width-tab-${index}`,
  'aria-controls': `full-width-tabpanel-${index}`,
});

const AdminPanel = () => {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const theme = useTheme();

  const checkAuth = useCallback(() => {
    if (Cookies.get('isLoggedIn') !== 'true' || !Cookies.get('jwt')) {
      navigate('/admin');
    }
  }, [navigate]);

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const tabComponents = [
    { label: 'Queries', component: <Queries /> },
    { label: 'Blogs', component: <Blogs /> },
    { label: 'Admins', component: <Admins /> },
    // { label: 'Images/Videos', component: <ImagesVideos /> },
  ];

  return (
    <Box sx={{ bgcolor: 'background.paper', width: '100%' }}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="admin panel tabs"
        >
          {tabComponents.map((tab, index) => (
            <Tab key={tab.label} label={tab.label} {...a11yProps(index)} />
          ))}
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        {tabComponents.map((tab, index) => (
          <TabPanel key={tab.label} value={value} index={index} dir={theme.direction}>
            {tab.component}
          </TabPanel>
        ))}
      </SwipeableViews>
    </Box>
  );
};

export default AdminPanel;
