import React, { Component } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Form from '../Form'

import './style.scss'

class HeroSlider extends Component {
    render() {
        const settings = {
            dots: true,
            infinite: true,
            arrows: true,
            speed: 1000,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 3000,
        };
        return (



            <Slider className={this.props.className} {...settings}>
                {this.props.sliders.map((slider, i) => (
                    <div
                        key={i}
                        className={`slideWrapper ${slider.images}`}
                    >
                        <div
                            className="sliderContent">
                            <div className="container">
                                <div className="row">
                                    <div style={{ textAlign: "center" }}>

                                        <h2 style={{ color: "white" }}>{slider.title} <span style={{ color: "white" }}></span></h2>
                                        <p><span></span><br /><br /><span>{slider.subTitle2}</span></p>

                                        <div className="btnStyle">
                                            <a href="#contactArea">{slider.button}</a>
                                        </div>
                                    </div>
                                    {/* <div className="col-12 col-lg-7">
                                        {/* <Form /> */}

                                </div>
                            </div>
                        </div>
                    </div>

                ))}
            </Slider>
        )
    }
}
export default HeroSlider