import React from "react";
import "./style.scss";
import HeaderMiddle from "../HeaderMiddle";

const HeaderTop = (props) => {
  return (
    <>
      <div className={`headerArea ${props.className}`}>
        {/* Top Header */}
        <div className="headerTop">
          <div className="container">
            {/* Left: Address */}
            <ul className="accountLoginArea">
              <li>
                <i className="fa fa-map-marker"></i>
                <span> Delhi | Noida | Allahabad | Gorakhpur </span>
              </li>
            </ul>

            {/* Right: Button */}
            <div className="btnStyle">
            <a href="https://wa.me/+919284113696?text=Hello%2C%0AWelcome%20to%20Legal%20Corridor%2C%20please%20let%20me%20know%20how%20I%20can%20help%20you%20with%20your%20legal%20requirement?" target="_blank" rel="noopener noreferrer" style={{fontWeight:"900"}}>
                Free Consultation
            </a>
            </div>
          </div>
        </div>
      </div>
      <HeaderMiddle />
    </>
  );
};

export default HeaderTop;
