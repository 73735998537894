import React, { Fragment } from 'react'
import HeaderBotton from '../../../components/HeaderBottom'
import HeaderTop from '../../../components/HeaderTop'
import HeroSlider from '../../../components/HeroSlider'
import Service from '../../../components/Service'
import About from '../../../components/About'
import ServiceArea from '../../../components/ServiceArea'
import Portfolio from '../../../components/Portfolio'
import Testmonial from "../../../components/Testmonial";
import ContactArea from '../../../components/ContactArea'
import TeamMember from '../../../components/TeamMember'
import CounterArea from '../../../components/CounterArea'
import BlogArea from '../../../components/BlogArea'
import NewsLetter from '../../../components/Newsletter'
import FooterArea from '../../../components/FooterArea'
// images
import about from '../../../images/about/2.jpg'
import signature from '../../../images/about/1.png'
import CookieConsent, { Cookies, resetCookieConsentValue } from "react-cookie-consent";


// images
import portfolio1 from '../../../images/studies/1.jpg'
import portfolio2 from '../../../images/studies/2.jpg'
import portfolio3 from '../../../images/studies/3.jpg'
import portfolio4 from '../../../images/studies/4.jpg'
import portfolio5 from '../../../images/studies/5.jpg'

//services image
import checkBounce from '../../../images/services/check-bounce.png'
import divorce from '../../../images/services/divorce.jpg'
import contract from '../../../images/services/contract.jpg'
import dispute from '../../../images/services/dispute.jpeg'
import property from '../../../images/services/property.jpg'
import criminal from '../../../images/services/criminal.jpg'


const aboutText = [
    { text: "Founded by Aman Verma, Legal Corridor is a boutique multi-disciplinary firm engaged in providing professional legal services. A leading law firm in India, we handle an array of litigation in the Supreme Court of India, the High Court of Delhi, and the district courts in Delhi, Noida and Gurgaon." },
    { text: '<br />The firm has a diverse practice area focused on litigation in the areas of money recovery, commertial dispute between companies, family law, IPR Laws, RERA, property, succession and inheritance, consumer, civil and criminal litigation. It also advises on estate planning, and due diligence. <br /><br />' },
]

const heroSliders = [
    {
        images: 'slideWrapperOne',
        title: 'Explore our services: covering every sector and every need',
        subTitle1: "Explore Legal Corridor's extensive legal expertise across industries",
        subTitle2: "offering clear and effective strategies for your unique challenges",
        text: 'The Most Talented Law Frim',
        button: 'Explore our services'
    },
    {
        images: 'slideWrapperTwo',
        title: 'Connect confidently: We are here to offer assistance.',
        subTitle1: "Connect confidently with Legal Corridor, where expert assistance awaits for your legal needs.",
        subTitle2: "",
        text: 'The Most Talented Law Frim',
        button: 'Contact us now'
    },
    {
        images: 'slideWrapperThree',
        title: 'Facilitating legal solutions: Get Free Legal Advice',
        subTitle1: "Connect confidently with Legal Corridor, where expert assistance awaits for your legal needs.",
        subTitle2: "Providing Legal Services for nri- Indian Overseas And For Foreign Internationals In India",
        text: 'The Most Talented Law Frim',
        button: 'Contact us now'
    },
]

const services = [
    {
        img: divorce,
        icon: 'flaticon-parents',
        title: 'Divorce Law / Family Court Matters',
        content: 'At Legal Corridor, we have developed a strong expertise in handling family matters, consistently securing favorable judgments and orders.Our experience spans a wide range of issues, including marriages, child adoption, child custody, marital breakdown, maintenance, and more delicate cases such as domestic violence and divorce. We have successfully advocated and resolved these matters efficiently and effectively.'
    },
    {
        img: checkBounce,
        icon: 'flaticon-wounded',
        title: 'Recovery/ Cheque Bounce Matters',
        content: 'At Legal Corridor, we have provided comprehensive advice on all aspects of banking law, including banking regulations, secured commercial lending, and raising loan finance. Our services include: Advising domestic and international clients on foreign exchange regulations related to investments and transactions in the banking sector.Drafting and reviewing term loan documentation and negotiable instruments for various banks and financial institutions.'
    },
    {
        img: dispute,
        icon: 'flaticon-employee',
        title: 'Corporate & Commercial Litigation',
        content: 'At Legal Corridor, we represent clients in both established and emerging markets, handling all phases of local and international litigation. Regardless of their location, our clients benefit from the exceptional support provided by our attorneys. Commercial disputes often involve high stakes and can have a significant global impact. Both individuals and organizations strive to proactively minimize their risks.'
    },
    {
        img: criminal,
        icon: 'flaticon-thief',
        title: 'Criminal Law',
        content: 'Our lawyers at Legal Corridor are esteemed specialists in criminal law. We are renowned for tackling complex cases and achieving optimal outcomes for our clients. Our solicitors offer expert guidance across all facets of criminal defense law, committed to securing the most favorable results and delivering exceptional defense services.'
    },
    {
        img: contract,
        icon: 'flaticon-university-graduate-hat',
        title: 'Contract Drafting',
        content: "At Legal Corridor, we specialize in providing comprehensive corporate legal services tailored specifically for startups. Our experienced team understands the unique challenges and opportunities that new businesses face. "
    },
    {
        img: property,
        icon: 'flaticon-house',
        title: 'Property/ Real Estate Law',
        content: 'At Legal Corridor, we support our clients at every stage, offering premier legal advice to simplify the process and protect their interests. Buying and selling real estate involves substantial financial investment and meticulous planning, often leading to disputes. Both parties must meet various requirements before and after closing, and litigation can arise if either party fails to uphold their obligations. '
    },
]

const portfolioItem = [
    { images: portfolio1, title: 'General Service', subtitle: 'Corporate' },
    { images: portfolio2, title: 'Personal Issue', subtitle: 'General' },
    { images: portfolio3, title: 'Business Accounting', subtitle: 'Business' },
    { images: portfolio4, title: 'Accounting issue', subtitle: 'Criminal' },
    { images: portfolio5, title: 'Business Accounting', subtitle: 'Family Issue' }
]

const disclaimer = "Disclaimer & Confirmation As per the rules of the Bar Council of India, we are not permitted to solicit work and advertise. By clicking on the “I agree” below, the user acknowledges the following: There has been no advertisement, personal communication, solicitation, invitation or inducement of any sort whatsoever from us or any of our members to solicit any work through this website; The user wishes to gain more information about us for his/her own information and use; The information about us is provided to the user only on his/her specific request and any information obtained or materials downloaded from this website is completely at the user’s volition and any transmission, receipt or use of this site would not create any lawyer-client relationship. The information provided under this website is solely available at your request for informational purposes only, should not be interpreted as soliciting or advertisement. We are not liable for any consequence of any action taken by the user relying on material / information provided under this website. In cases where the user has any legal issues, he/she in all cases must seek independent legal advice.New and highly discounted products, fresh and hot stories & useful information";

const HomePageOne = () => {
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBotton className="headerBottomArea" />
            </header>
            <HeroSlider
                sliders={heroSliders}
                className="heroSliderArea" />
            <About
                className="aboutArea"
                title="About Us"
                images={about}
                signature={signature}
                pragraphs={aboutText}
            />
            <ServiceArea
                className="ourServiceArea"
                title="We Offer Broad Range Of Legal Services"
                subTitle=""
                services={services}
            />


            <section id="contactArea">
                <ContactArea
                    className="contactArea"
                />
            </section>

            <BlogArea
                className="blogArea"
                title="Latest News"
                subTitle="From Our Blog
                "
            />
            <CookieConsent
                onAccept={() => {
                    resetCookieConsentValue()
                }}
            >{disclaimer}</CookieConsent>

            {/* <NewsLetter
                className="newsLetterArea"
            /> */}
            <FooterArea />
        </Fragment>
    )
}
export default HomePageOne