import React from "react";
import "./style.scss";
import { NavLink } from "react-router-dom";
import logo from "../../images/logo/logo.png";

const HeaderMiddle = () => {
  return (
    <div className="headerMiddle">
      <div className="container">
        <div className="logo">
          <NavLink to="/">
            <img
              src={logo}
              alt="Legal Corridor Logo"
              style={{ height: "100px", width:"320px" }}
            />
          </NavLink>
        </div>
        {/* Center: Phone Numbers */}
        <div className="contactDetails">
          <div className="subText">Have questions? Call us now:</div>
          <p 
  className="phoneNumber" 
  style={{
    color: "#777", 
    fontFamily: "Arial, sans-serif", 
    fontSize: "30px", 
    fontWeight: "bold"
  }}
>
  <a href="tel:+91-9555690946" style={{color:"#777"}}>+91-9555 690 946</a>  | <a href="tel:+91-7905705906" style={{color:"#777"}}>+91-7905 705 906</a>
</p>
        </div>
      </div>
    </div>
  );
};

export default HeaderMiddle;
