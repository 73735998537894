import React from "react";
import SectionTitle from '../Title'
import './style.scss'

const ServiceArea = ({ className, title, subTitle, services }) => {

    const redirectToContactArea = () => {
        window.location.href = '/contact';
    };

    return (
        <>
            <div className={className}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <SectionTitle
                                title={title}
                                subTitle={subTitle}
                            />
                        </div>
                        {services.map((service, index) => (
                            <div key={index} className="col-lg-4 col-md-6" onClick={() => redirectToContactArea()}>
                                <div className="serviceWrap">
                                    <div className="serviceIcon">
                                        <img src={service.img} alt="" />
                                    </div>
                                    <div className="serviceContent">
                                        <h3>{service.title}</h3>
                                        <p>{service.content}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}
export default ServiceArea