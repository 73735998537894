import React from 'react'
import Form from '../Form'
import './style.scss'
import appointmentImage from '../../images/calendar.png';
import clockImage from '../../images/clock.png';
import locationImage from '../../images/location.png';


const ContactArea = ({ className }) => {
    return (
        <div className={className}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 col-12">
                        <div className="contactInfo">
                            {/* <span>For Our Honorabe Clients</span> */}
                            <p>Legal Corridor is acknowledged as the premier law firm in Delhi, committed to delivering efficient, effective, and high-quality services to our clients. Our commitment to excellence is guided by core values of integrity, teamwork, diligence, and unwavering dedication. These values form the foundation of our service delivery standards outlined below.
                                <br />
                                Our attorneys are expected to be resolute, professional, and knowledgeable, with a strong commitment to upholding the law and safeguarding client rights. Ideal candidates will demonstrate innate leadership abilities alongside exceptional interpersonal, written, and oral communication skills.</p>
                            <h6>Call us 24/7 at +91-95556 90946 or fill out the form.</h6>
                        </div>
                        <div style={{ display: 'flex', height: '60px', marginTop: "20px", fontSize: "10px" }}>
                            <div style={{ flex: 1, backgroundColor: '#f0f0f0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <div>
                                    <img src={clockImage} alt="" style={{ height: "30px" }} />
                                    &nbsp; 24/7 support
                                </div>
                            </div>
                            <div style={{ flex: 1, backgroundColor: '#f0f0f0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <div>
                                    <img src={appointmentImage} alt="" style={{ height: "30px" }} />
                                    &nbsp; Appointment
                                </div>
                            </div>
                            <div style={{ flex: 1, backgroundColor: '#f0f0f0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <div>
                                    <img src={locationImage} alt="" style={{ height: "30px" }} />
                                    &nbsp; Delhi, NCR
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-lg-7" style={{ textAlign: "center" }}>
                        <h2 style={{ color: "white" }}>Get Free Consultation Online</h2>
                        <Form />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ContactArea