import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import axios from 'axios';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CircularProgress from '@mui/material/CircularProgress';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { styled } from '@mui/system'; // Material-UI styling

import BlogCard from './BlogCard';
const Blogs = () => {

    const ResizableContainer = styled('div')({
        resize: 'both',
        overflow: 'auto',
        border: '1px solid #ccc',
        padding: '8px',
        minHeight: '200px', // Minimum height of the container
        maxHeight: '100%', // Prevent overflow
    });

    const [loading, setLoading] = useState(true);
    const [blogs, setBlogs] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false)
    const [blogTitle, setBlogTitle] = useState('')
    const [blogContent, setBlogContent] = useState('')
    const [creatorName, setCreatorName] = useState('')
    const [selectedFile, setSelectedFile] = useState('');
    const [isEdit, setIsEdit] = useState(false)
    const [editedBlogId, setEditedBlogId] = useState('')

    const fetch = async () => {
        try {
            const BACKEND_HOST = process.env.REACT_APP_BACKEND_HOST;
            const response = await axios.get(`${BACKEND_HOST}/blogs`, {
            });
            if (response.data.code === 200) {
                setBlogs(response.data.data)
                setLoading(false);
            } else {
                toast.error(response.data.code)
            }
        } catch (error) {
            toast.error(error)
            setLoading(false)
        }
    };

    useEffect(() => {
        fetch()
    }, []);
    const isFormValid = () => {
        console.log(blogTitle ,blogContent ,creatorName ,selectedFile);
        
        if (!blogTitle || !blogContent || !creatorName || !selectedFile) {
            return false;
        }
        return true;
    };


    const createBlog = async () => {
        try {
            if (!isFormValid()) {
                toast.error('Please fill in all the fields.');
                return;
            }
            setLoading(true)
            const BACKEND_HOST = process.env.REACT_APP_BACKEND_HOST;

            // Create a FormData object to send form data including the file
            const formData = new FormData();
            formData.append('title', blogTitle);
            formData.append('content', blogContent);
            formData.append('creator_name', creatorName);
            formData.append('creation_date', Date());
            formData.append('upload', selectedFile);

            const response = await axios.post(`${BACKEND_HOST}/blogs`, formData, {
                headers: {
                    Authorization: 'Bearer ' + Cookies.get('jwt'),
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log(response);
            if (response.data.code === 200) {
                setBlogs(response.data.data);
                fetch()
                setLoading(false);
                setDialogOpen(false)

            } else {
                toast.error(response.data.code);
            }
        } catch (error) {
            toast.error(error);
            setLoading(false);
            setDialogOpen(false)
        }
    };



    const deleteBlog = async (id) => {
        const BACKEND_HOST = process.env.REACT_APP_BACKEND_HOST;
        let config = {
            method: 'delete',
            maxBodyLength: Infinity,
            url: `${BACKEND_HOST}/blogs/${id}`,
            headers: {
                'Authorization': 'Bearer ' + Cookies.get('jwt')
            }
        };
        console.log(config);

        axios.request(config).then((response) => {

                if (response.data.code === 200) {
                    fetch()
                    toast.success('deleted successfully')
                    setLoading(false)
                }
            })
            .catch((error) => {
                toast.error(error)
                setLoading(false)
            });
    }

    const prefillDialog = (blog) => {
        
        setIsEdit(true)
        setDialogOpen(true)
        setBlogTitle(blog.title)
        setBlogContent(blog.content)
        setCreatorName(blog.creator_name)
        setEditedBlogId(blog._id)
        setSelectedFile(blog.banner_url)
    }
    const editBlog = (editedBlogId) => {
        setLoading(true)
        console.log(selectedFile)

        if (!isFormValid()) {
            toast.error('Please fill in all the fields.');
            return;
        }
        const data = new FormData();
        data.append('title', blogTitle);
        data.append('content', blogContent);
        data.append('creator_name', creatorName);
        data.append('creation_date', Date());
        data.append('upload', selectedFile);
        const BACKEND_HOST = process.env.REACT_APP_BACKEND_HOST;
        let config = {
            method: 'put',
            maxBodyLength: Infinity,
            url: `${BACKEND_HOST}/blogs/${editedBlogId}`,
            headers: {
                'Authorization': 'Bearer ' + Cookies.get('jwt')
            },
            data: data
        };

        axios.request(config)
            .then((response) => {
                if (response.data.code == 200) {
                    fetch()
                    toast.success('edited successfully')
                    setLoading(false)
                    setIsEdit(false)
                    setDialogOpen(false)
                }
            })
            .catch((error) => {
                toast.error(error)
                setLoading(false)
                setIsEdit(false)
                setDialogOpen(false)
            }).finally(() => {
                setIsEdit(false)
                setBlogTitle("")
                setBlogContent("")
                setCreatorName("")
            });

    }

    const closeDialog = () => {
        setDialogOpen(false)
        setIsEdit(false)
        setBlogTitle("")
        setBlogContent("")
        setCreatorName("")
    }


    return (
        <div>
            <div style={{ width: "100%", textAlign: "center", padding: "10px" }}>
                <Button variant="contained" color="success" onClick={() => setDialogOpen(true)}>
                    Create Blog
                </Button>
            </div>
            <>
                {!loading && blogs ?
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
                        {blogs.map((item) => (
                            <React.Fragment key={item._id}> {/* Add a unique key for each item */}
                                <BlogCard data={item} deleteBlog={deleteBlog} prefillDialog={prefillDialog} />
                                {/* <Divider variant="inset" component="li" /> */}
                            </React.Fragment>
                        ))}
                    </div>
                    :
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                        <CircularProgress />
                    </div>
                }
            </>

            <Dialog open={dialogOpen} fullScreen>
                <DialogTitle>Create Blog</DialogTitle>
                <DialogContent>
                    <DialogContentText></DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Blog Title"
                        type="text"
                        fullWidth
                        value={blogTitle}
                        onChange={(e) => setBlogTitle(e.target.value)}
                    />
                    
                    {/* Resizable ReactQuill */}
                    <ResizableContainer>
                        <ReactQuill
                            value={blogContent}
                            onChange={(value) => setBlogContent(value)}
                            theme="snow"
                            placeholder="Write your blog content here..."
                            style={{ height: "100%", width: "100%" }}
                        />
                    </ResizableContainer>

                    <TextField
                        margin="dense"
                        label="Creator Name"
                        type="text"
                        fullWidth
                        value={creatorName}
                        onChange={(e) => setCreatorName(e.target.value)}
                    />
                    <input type="file" onChange={(e) => setSelectedFile(e.target.files[0])} />
                </DialogContent>

                <DialogActions>
                    <Button onClick={isEdit ? () => editBlog(editedBlogId) : createBlog}>
                        {isEdit ? 'Edit' : 'Create'}
                    </Button>
                    <Button onClick={closeDialog}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default Blogs;
