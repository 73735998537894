import React, { Fragment, Component } from 'react';
import { BrowserRouter, Routes, Route, } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import Admin from '../Pages/Admin';
import AdminPanel from '../Pages/AdminPanel';
import HomePageOne from '../Pages/HomePageOne'
import AboutPage from '../Pages/AboutPage'
import PracticePage from '../Pages/PracticePage'
import PracticeSinglePage from '../Pages/PracticeSinglePage'
import TeamPage from '../Pages/TeamPage'
import ContactPage from '../Pages/ContactPage'
import BlogFullWidth from '../Pages/BlogFullWidth'
import './App.css';
import BlogPage from '../../components/Blog';

class App extends Component {
    render() {
        return (
            <Fragment>
                <BrowserRouter>
                    <ToastContainer autoClose={2500} position="top-center" />
                    <Routes>
                        <Route exact path="admin" element={<Admin />} />
                        <Route exact path="adminPanel" element={<AdminPanel />} />
                        <Route exact path="/" element={<HomePageOne />} />
                        {/* <Route exact path="home-two" element={<HomePageTwo />} />
                        <Route exact path="home-three" element={<HomePageThree />} /> */}
                        <Route exact path="about" element={<AboutPage />} />
                        <Route exact path="practice" element={<PracticePage />} />
                        <Route exact path="practice-details" element={<PracticeSinglePage />} />
                        {/* <Route exact path="team" element={<PortfolioPage />} /> */}
                        {/* <Route exact path="blogs/blog-details" element={<BlogDetails />} /> */}
                        <Route exact path="blogs" element={<BlogFullWidth />} />
                        {/* <Route exact path="blogs-single" element={<BlogRightPage />} /> */}
                        <Route exact path="contact" element={<ContactPage />} />
                        {/* <Route exact path="blog-left" element={<BlogLeftPage />} />
                        <Route exact path="blog-right" element={<BlogRightPage />} />
                        <Route exact path="blog-fullwidth" element={<BlogFullWidth />} />
                        <Route exact path="blog-fullwidth" element={<BlogFullWidth />} /> */}
                        <Route exact path="team" element={<TeamPage />} />
                        <Route exact path="blog/:blogId" element={<BlogPage />} />
                    </Routes>
                </BrowserRouter>
            </Fragment >
        );
    }
}

export default App;
