import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CircularProgress from '@mui/material/CircularProgress';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

const Admins = () => {

    const [loading, setLoading] = useState(true);
    const [admins, setAdmins] = useState(null);
    const [dialogOpen,setDialogOpen]=useState(false)
    const [adminUsername,setAdminUsername]=useState('')
    const [adminPassword,setAdminPassword]=useState('')

    const fetch = async () => {
        try {
            const BACKEND_HOST = process.env.REACT_APP_BACKEND_HOST;
            const response = await axios.get(`${BACKEND_HOST}/admin/all`, {
                headers: {
                    'Authorization': 'Bearer '+Cookies.get('jwt')
                },
            });
            console.log(response.data);
            if (response.data.code === 200) {
                setAdmins(response.data.data)
                setLoading(false);
            } else {
                toast.error(response.data.code)
            }
        } catch (error) {
          toast.error(error)
          setLoading(false)
        }
    };

    useEffect(() => {
        fetch()
    }, []);

    const isFormValid = () => {
        if (!adminUsername || !adminPassword) {
          return false;
        }
        return true;
      };


      const createAdmin = async () => {
        try {
            if (!isFormValid()) {
                toast.error('Please fill in all the fields.');
                return;
            }
    
            // Validate username and password length
            if (adminUsername.length < 3 || adminPassword.length < 7) {
                toast.error('Username must be at least 3 characters and password must be at least 7 characters.');
                return;
            }
    
            setLoading(true);
            const BACKEND_HOST = process.env.REACT_APP_BACKEND_HOST;
    
            // Create a FormData object to send form data including the file
            const data = JSON.stringify({
                'new_admin_email': adminUsername,
                'new_admin_password': adminPassword
            });
    
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${BACKEND_HOST}/admin/register`,
                headers: { 
                    'Content-Type': 'application/json', 
                    'Authorization': 'Bearer ' + Cookies.get('jwt')
                },
                data: data
            };
    
            const response = await axios.request(config);
            if (response.data.code === 200) {
                fetch();
                setLoading(false);
                setDialogOpen(false);
                setAdminUsername("");
                setAdminPassword("");
            } else {
                toast.error(response.data.error);
                setLoading(false);
            }
        } catch (error) {
            toast.error(error);
            setLoading(false);
            setDialogOpen(false);
            setAdminUsername("");
            setAdminPassword("");
        }
    };
    


    const deleteAdmin=async(id)=>{
        try {
            const BACKEND_HOST = process.env.REACT_APP_BACKEND_HOST;
            const response = await axios.delete(`${BACKEND_HOST}/admin/${id}`, {
                headers: {
                    'Authorization': 'Bearer '+Cookies.get('jwt')
                },
            });
            if (response.data.code === 200) {
                setLoading(true);
                fetch()
                toast.success(response.data.message)
            } else {
                toast.error(response.data.code)
            }
        } catch (error) {
          toast.error(error)
          setLoading(false)
        }
    }
    const closeDialog=()=>{
        setDialogOpen(false)
        setAdminUsername("")
        setAdminPassword("")
    }


    return (
        <>
        <div style={{width:"100%",textAlign:"center",padding:"10px"}}>
            <Button variant="contained" color="success" onClick={()=>setDialogOpen(true)}>
                Create Admin
            </Button>
            </div>
        <div>
            {!loading && admins ?
                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                {admins.map((item) => (
                    <React.Fragment key={item._id}> {/* Add a unique key for each item */}
                        <ListItem 
                        alignItems="flex-start"  
                        secondaryAction={
                            <IconButton edge="end" aria-label="comments" onClick={()=>deleteAdmin(item._id)}>
                                <DeleteIcon />
                            </IconButton>
                        }>
                            <ListItemAvatar>
                                <Avatar alt={item.username} src="https://www.svgrepo.com/show/382099/female-avatar-girl-face-woman-user-2.svg" />
                            </ListItemAvatar>
                            <ListItemText
                                primary={item.username}
                            
                            />
                        </ListItem>
                        <Divider variant="inset" component="li" />
                    </React.Fragment>
                ))}
            </List>
                :
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                    <CircularProgress />
                </div>
            }
            <Dialog open={dialogOpen}>
                <DialogTitle>Create Admin</DialogTitle>
                <DialogContent>
                    <DialogContentText></DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Admin Username/Email"
                        type="text"
                        fullWidth
                        value={adminUsername}
                        onChange={(e) => setAdminUsername(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        label="Admin Password"
                        type="text"
                        multiline
                        fullWidth
                        value={adminPassword}
                        onChange={(e) => setAdminPassword(e.target.value)}
                    />
                </DialogContent>

                <DialogActions>
            
                <Button onClick={createAdmin} >
                    Create
                </Button>
                <Button onClick={closeDialog} >
                    Close
                </Button>
                </DialogActions>
            </Dialog>
        </div>
        </>
    );
};

export default Admins;
