import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./style.scss";

import HeaderBotton from '../../components/HeaderBottom'
import HeaderTop from '../../components/HeaderTop'
import Breadcumb from '../../components/Breadcumb'
import TeamMember from '../../components/TeamMember'
import NewsLetter from '../../components/Newsletter'
import FooterArea from '../../components/FooterArea'
// images
import breadcumb from '../../images/breadcumb/1.jpg'

import './style.scss'

const breadcumbMenu = [
    { name: 'Home', route: '/' },
    { name: 'Attorneys' }
]

const BlogPage = () => {
  const { blogId } = useParams();
  const [blogData, setBlogData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/blogs/${blogId}`);
        const data = await response.json();
        console.log(data);
        
        setBlogData(data);
      } catch (error) {
        console.error("Error fetching blog:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBlog();
  }, [blogId]);

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  if (!blogData) {
    return <div className="error">Blog not found</div>;
  }

  return (
    <>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBotton className="headerBottomArea headerBottomAreaStyelTwo" />
            </header>
            <Breadcumb
                className="breadcumbArea"
                title="Our Attorneys"
                breadcumbMenu={breadcumbMenu}
                background={breadcumb}
            />
        
        <div className="blogPage">
      <div className="container">
        <div className="contentWrapper">
          <div className="blogContent">
            <h1 className="blogTitle">{blogData.title}</h1>
            <div className="blogBreadcrumb">{blogData.breadcrumb}</div>
            <img className="blogImage" src={blogData.banner_url} alt={blogData.title} />
            <div
              className="blogDescription"
              dangerouslySetInnerHTML={{ __html: blogData.content }}
            />
          </div>

          <div className="queryForm">
            <h2>Get Expert Consultation</h2>
            <p>Leave your contact details and our experts will call you back</p>
            <form>
              <input type="text" placeholder="Name *" required />
              <input type="email" placeholder="E-mail *" required />
              <input type="tel" placeholder="Telephone *" required />
              <input type="text" placeholder="Country" />
              <input type="text" placeholder="City" />
              <textarea placeholder="Message"></textarea>
              <button type="submit">Submit</button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <NewsLetter
        className="newsLetterArea"
    />
    <FooterArea />
    </>
  );
};

export default BlogPage;
