import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CircularProgress from '@mui/material/CircularProgress';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
const Queries = () => {

    const [loading, setLoading] = useState(true);
    const [queries, setQueries] = useState(null);

    const fetch = async () => {
        try {
            const BACKEND_HOST = process.env.REACT_APP_BACKEND_HOST;
            const response = await axios.get(`${BACKEND_HOST}/query`, {
                headers: {
                    'Authorization': 'Bearer ' + Cookies.get('jwt')
                },
            });
            if (response.data.code === 200) {
                setQueries(response.data.data)
                setLoading(false);
            } else {
                toast.error(response.data.code)
            }
        } catch (error) {
            toast.error(error)
            setLoading(false)
        }
    };

    useEffect(() => {

        fetch()
    }, []);


    const deleteQuery = async (id) => {
        try {
            const BACKEND_HOST = process.env.REACT_APP_BACKEND_HOST;
            const response = await axios.delete(`${BACKEND_HOST}/query/${id}`, {
                headers: {
                    'Authorization': 'Bearer ' + Cookies.get('jwt')
                },
            });
            if (response.data.code === 200) {
                setLoading(true);
                fetch()
            } else {
                toast.error(response.data.code)
            }
        } catch (error) {
            toast.error(error)
            setLoading(false)
        }
    }


    return (
        <div>
            {!loading && queries ?
                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    {queries.map((item) => (
                        <React.Fragment key={item._id}> {/* Add a unique key for each item */}
                            <ListItem
                                alignItems="flex-start"
                                secondaryAction={
                                    <IconButton edge="end" aria-label="comments" onClick={() => deleteQuery(item._id)}>
                                        <DeleteIcon />
                                    </IconButton>
                                }>
                                <ListItemAvatar>
                                    <Avatar alt={item.name} src="https://www.svgrepo.com/show/382099/female-avatar-girl-face-woman-user-2.svg" />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={item.name}

                                    secondary={
                                        <React.Fragment>
                                            <Typography
                                                sx={{ display: 'inline' }}
                                                component="span"
                                                variant="body2"
                                                color="text.primary"
                                            >
                                                <>
                                                    {item.phone}
                                                    {item.email != '' ? <><br />{item.email}</> : null}
                                                    {item.city != '' ? <><br />{item.city}</> : null}
                                                    {item.createdAt != '' ? <><br />{item.createdAt}</> : null}
                                                </>
                                            </Typography>
                                            <br />
                                            {item.issue}
                                        </React.Fragment>
                                    }
                                />
                            </ListItem>
                            <Divider variant="inset" component="li" />
                        </React.Fragment>
                    ))}
                </List>
                :
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                    <CircularProgress />
                </div>
            }
        </div>
    );
};

export default Queries;
