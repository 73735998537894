import React, { Fragment } from 'react'
import HeaderBotton from '../../../components/HeaderBottom'
import HeaderTop from '../../../components/HeaderTop'
import Breadcumb from '../../../components/Breadcumb'
import Service from '../../../components/Service'
import About from '../../../components/About'
import ServiceArea from '../../../components/ServiceArea'
import Testmonial from "../../../components/Testmonial";
import TeamMember from '../../../components/TeamMember'
import CounterArea from '../../../components/CounterArea'
import BlogArea from '../../../components/BlogArea'
import NewsLetter from '../../../components/Newsletter'
import FooterArea from '../../../components/FooterArea'
// images
import about from '../../../images/about/2.jpg'
import breadcumb from '../../../images/breadcumb/about-practice.jpg'

import './style.scss'

const aboutText = [
    {
        text: `Legal Corridor is a premier law firm based in Delhi, led by the esteemed Adv. Aman Verma. With a commitment to delivering exceptional legal services, we specialize in a diverse range of practice areas to meet the needs of our clients.\n\nOur Mission and Values\nOur mission at Legal Corridor is to provide unparalleled legal representation while upholding the highest standards of integrity, professionalism, and client care. We are dedicated to:\n\nClient-Centric Approach: Prioritizing our clients' needs and tailoring our services to achieve their goals.\nExcellence: Striving for excellence in every case we handle, ensuring thorough preparation and strategic execution.\nIntegrity: Maintaining the utmost honesty and transparency in all our dealings.\n\nOur Leader\nAdv. Aman Verma, the guiding force behind Legal Corridor, brings a wealth of experience and a profound understanding of the legal landscape. His strategic insights and unwavering commitment to justice have earned him a stellar reputation among clients and peers alike.\n\nOur Team\nOur team of highly skilled and dedicated legal professionals works collaboratively to provide comprehensive legal solutions. Each member of our team brings unique expertise and a passion for the law, ensuring that our clients receive the best possible representation.\n\nOur Achievements\nOver the years, Legal Corridor has successfully represented numerous clients, achieving landmark victories and favorable settlements. Our dedication to excellence has garnered recognition and accolades within the legal community.\n\nOur Location\nConveniently located in the heart of Delhi, Legal Corridor is easily accessible to clients throughout the region. Our state-of-the-art office provides a welcoming environment where clients can discuss their legal matters in confidence and comfort.`
    }
];

const services = [
    {
        icon: 'flaticon-parents',
        title: 'Family Law',
        content: 'There are many variations of passages of Lorem '
    },
    {
        icon: 'flaticon-wounded',
        title: 'Personal Injury',
        content: 'There are many variations of passages of Lorem '
    },
    {
        icon: 'flaticon-employee',
        title: 'Business Law',
        content: 'There are many variations of passages of Lorem '
    },
    {
        icon: 'flaticon-thief',
        title: 'Criminal Law',
        content: 'There are many variations of passages of Lorem '
    },
    {
        icon: 'flaticon-university-graduate-hat',
        title: 'Education Law',
        content: 'There are many variations of passages of Lorem '
    },
    {
        icon: 'flaticon-house',
        title: 'Real Estate Law',
        content: 'There are many variations of passages of Lorem '
    },
]

const breadcumbMenu = [
    { name: 'Home', route: '/' },
    { name: 'About us' }
]

const AboutPage = () => {
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBotton className="headerBottomArea headerBottomAreaStyelTwo" />
            </header>
            <Breadcumb
                className="breadcumbArea"
                title="About Us"
                breadcumbMenu={breadcumbMenu}
                background={breadcumb}
            />
            {/* <Service className="serviceArea mt-0" /> */}
            <About
                className="aboutArea aboutAreaStyleTwo"
                title=""
                subTitle=""
                images={about}
                orderLast="order-last"
                videoId="XxVg_s8xAms"
                pragraphs={aboutText}
            />
            {/* <ServiceArea
                className="ourServiceArea ourServiceAreaStyleTwo"
                title="How Can We Help You"
                subTitle="Area Of Practice"
                services={services}
            />
            <Testmonial
                className="testmonialArea pt100"
            />
            <CounterArea
                fullWidth={true}
                className="counterArea counterAreaStyleTwo"
            />
            <TeamMember
                title="Qualified Attorneys "
                subTitle="Meet Our Experts"
                className="teamArea"
                slider={true}
            />
            <BlogArea
                className="blogArea"
                title="Latest News"
                subTitle="From Our Blog
                "
            /> */}
            {/* <NewsLetter
                className="newsLetterArea"
            /> */}
            <FooterArea />
        </Fragment>
    )
}
export default AboutPage