import React, { Fragment } from 'react'
import HeaderBotton from '../../../components/HeaderBottom'
import HeaderTop from '../../../components/HeaderTop'
import Breadcumb from '../../../components/Breadcumb'
import NewsLetter from '../../../components/Newsletter'
import FooterArea from '../../../components/FooterArea'
import Form from '../../../components/Form'
// images
import breadcumb from '../../../images/breadcumb/contact.jpeg'

import './style.scss'

const breadcumbMenu = [
    { name: 'Home', route: '/' },
    { name: 'Contact', },
]

const ContactPage = () => {
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBotton className="headerBottomArea headerBottomAreaStyelTwo" />
            </header>
            <Breadcumb
                className="breadcumbArea"
                title="Contact"
                breadcumbMenu={breadcumbMenu}
                background={breadcumb}
            />

            <div className="contactusPageArea">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="contactUsInfo">
                                <h3>Contact Us<br />
                                    WE WILL HERE FOR YOU!</h3>
                                {/* <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. 
                                </p>*/}
                                <h4 style={{ fontWeight: 900 }}>Address</h4>
                                <span >40, hanuman lane, 2nd floor,  baba kharak singh marg, connaught place, New Delhi-110001</span>
                                <h4 style={{ fontWeight: 900 }}>24/7 Phone</h4>
                                <span><a href="tel:+919555690946" >+91-9555690946</a></span>
                                {/* <span>0-123-456-7890</span> */}
                                <h4 style={{ fontWeight: 900 }}>Email</h4>
                                <span><a href="mailto:contact@legalcorridors.com" class="cta-button">contact@legalcorridors.com</a></span>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="contactUSForm">
                                <h3>Fill out the form below, we will get back you soon.
                                    REQUEST A CALLBACK</h3>
                                <Form
                                    addressInfo={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <NewsLetter
                className="newsLetterArea"
            /> */}
            <FooterArea />
        </Fragment >
    )
}
export default ContactPage